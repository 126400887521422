import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
  rotate?: number;
}

const ChevronIcon = (props: IIconProps) => {
  const { width = 20, className, rotate = 0 } = props;
  const height = (width * 20) / 20;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#111111"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 15l5-5h0L8 5"
        />
      </g>
    </svg>
  );
};

export default React.memo(ChevronIcon);
