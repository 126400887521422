module.exports = {
  siteUrl: `https://leadeconomy.com`, // Site domain. Do not include a trailing slash!

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: `Empowering Lead Generation`, // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: `Become Part of the Big Picture – Join Lead Economy`, // This allows an alternative site description for meta data for pages.

  shareImageWidth: `1000`, // Change to the width of your default share image
  shareImageHeight: `523`, // Change to the height of your default share image

  shortTitle: `Lead Economy`, // Used for App manifest e.g. Mobile Home Screen
  siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: `#ffffff`, // Used for Offline Manifest
  themeColor: `#13A878`, // Used for Offline Manifest
};
