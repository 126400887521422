import React from 'react';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

import { INavigationItem } from './Navigation';

import '../../styles/global.scss';

import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

interface ILayoutData {
  file: any;
  allGhostSettings: any;
}

interface ILayoutDataProp {
  data: ILayoutData;
}

export interface ISiteSettings {
  title: string;
  navigation: INavigationItem[];
}

export interface IDefaultLayoutProps {
  children: any;
  mainClass?: string;
  isHome?: boolean;
  isWhiteHeader?: boolean;
  postTitle?: string;
  headerClassName?: string;
}

interface IDefaultLayoutFullProps extends IDefaultLayoutProps, ILayoutDataProp {}

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({
  data,
  children,
  mainClass,
  headerClassName,
  isWhiteHeader,
}: IDefaultLayoutFullProps) => {
  const site = data.allGhostSettings.edges[0].node;

  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
      </Helmet>
      <Header site={site} className={headerClassName} isWhite={isWhiteHeader} />
      <main className={mainClass}>{children}</main>
      <Footer site={site} />
    </>
  );
};

const DefaultLayoutSettingsQuery = (props: IDefaultLayoutProps) => {
  const renderDefaultLayoutSettings = (data: ILayoutData) => (
    <DefaultLayout data={data} {...props} />
  );
  return (
    <StaticQuery
      query={graphql`
        query GhostSettings {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
          file(relativePath: { eq: "ghost-icon.png" }) {
            childImageSharp {
              fixed(width: 30, height: 30) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={renderDefaultLayoutSettings}
    />
  );
};

export default DefaultLayoutSettingsQuery;
