import React from 'react';

interface IIconProps {
  rotate?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const ArrowIcon = (props: IIconProps) => {
  const { rotate = 0, className, onClick } = props;

  return (
    <svg
      width="56"
      height="25"
      style={{ transform: `rotate(${rotate}deg)` }}
      className={className}
      onClick={onClick}
      viewBox="0 0 56 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#FFF" strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M41.453 1.686L54.225 13h0L41.453 24.314M53.871 13H.813" />
      </g>
    </svg>
  );
};

export default React.memo(ArrowIcon);
