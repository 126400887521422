import React from 'react';

import {
  IAuthorMetaFullProps,
  IAuthorMetaProps,
  IAuthorSettings,
} from '../../../interfaces/Author';

import getAuthorProperties from './getAuthorProperties';
import ImageMeta from './ImageMeta';

import { graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import config from 'utils/siteConfig';

const AuthorMeta = ({ data, settings: settingsData, canonical }: IAuthorMetaFullProps) => {
  const settings = settingsData.allGhostSettings.edges[0].node;

  const author = getAuthorProperties(data);
  const shareImage = author.image || _.get(settings, 'cover_image', null);
  const title = `${data.name} - ${settings.title}`;
  const description = data.bio || config.siteDescriptionMeta || settings.description;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(/^@/, '')}/`}
          />
        )}
        {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
        <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Person",
                        "name": ${JSON.stringify(data.name)},
                        ${author.sameAsArray ? `"sameAs": ${author.sameAsArray},` : ''}
                        "url": "${canonical}",
                        ${
                          shareImage
                            ? `"image": {
                                "@type": "ImageObject",
                                "url": "${shareImage}",
                                "width": "${config.shareImageWidth}",
                                "height": "${config.shareImageHeight}"
                            },`
                            : ''
                        }
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${config.siteUrl}"
                        },
                        "description": ${JSON.stringify(description)}
                    }
                `}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};

const AuthorMetaQuery = (props: IAuthorMetaProps) => {
  const renderAuthorMeta = (data: IAuthorSettings) => <AuthorMeta settings={data} {...props} />;
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsAuthorMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
        }
      `}
      render={renderAuthorMeta}
    />
  );
};

export default AuthorMetaQuery;
