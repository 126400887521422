import React from 'react';

import ErrorIcon from '../../images/Error';

import style from './Input.scss';

import cn from 'classnames';
import InputWithMask from 'react-text-mask';

export interface IInputProps {
  className?: string;
  type?: string;
  name: string;
  placeholder?: string;
  value?: string;
  hasError?: boolean;
  readOnly?: boolean;
  label?: string;
  helperText?: string;
  maxLength?: number;
  onChange: (input: { name: string; value: string }) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isAutoFocus?: boolean;
  isDisabled?: boolean;
  autoComplete?: 'on' | 'off';
  mask?: (Array<string | RegExp> | boolean) | ((value: string) => Array<string | RegExp> | boolean);
}

export interface IInputOnChange {
  name: string;
  value: string;
}

export function Input(props: IInputProps) {
  const {
    name = '',
    value = '',
    placeholder,
    label,
    type = 'text',
    onFocus,
    onBlur,
    onChange,
    maxLength,
    helperText,
    className,
    isDisabled,
    autoComplete = 'on',
    hasError,
    isAutoFocus = false,
  } = props;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (typeof onChange === 'function') {
      const input: IInputOnChange = { name, value };
      onChange(input);
    }
  };

  return (
    <div
      className={cn(className, style.wrapper, {
        [style.error]: hasError,
        [style.hasValue]: !!props.value,
        [style.hasLabel]: !!props.label,
      })}
    >
      {props.mask ? (
        <InputWithMask
          mask={props.mask}
          guide={false}
          id={name}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleInputChange}
          maxLength={maxLength}
          disabled={isDisabled}
          autoComplete={autoComplete}
          autoFocus={isAutoFocus}
        />
      ) : (
        <input
          id={name}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleInputChange}
          maxLength={maxLength}
          disabled={isDisabled}
          autoComplete={autoComplete}
          autoFocus={isAutoFocus}
        />
      )}
      <label htmlFor={name}>{label}</label>
      {hasError ? (
        <>
          <ErrorIcon className={style.errorIcon} />
          <span className={style.errorMessage}>{helperText}</span>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
