import { IAuthorData } from '../../../interfaces/Author';

import _ from 'lodash';

export const getAuthorProperties = (primaryAuthor: IAuthorData) => {
  let authorProfiles = [];

  authorProfiles.push(
    primaryAuthor.website ? primaryAuthor.website : undefined,
    primaryAuthor.twitter
      ? `https://twitter.com/${primaryAuthor.twitter.replace(/^@/, '')}/`
      : undefined,
    primaryAuthor.facebook
      ? `https://www.facebook.com/${primaryAuthor.facebook.replace(/^\//, '')}/`
      : undefined
  );

  authorProfiles = _.compact(authorProfiles);

  return {
    facebookUrl: primaryAuthor.facebook
      ? `https://www.facebook.com/${primaryAuthor.facebook.replace(/^\//, '')}/`
      : undefined,
    image: primaryAuthor.profile_image || undefined,
    name: primaryAuthor.name || undefined,
    sameAsArray: authorProfiles.length ? `["${_.join(authorProfiles, '", "')}"]` : undefined,
  };
};

export default getAuthorProperties;
