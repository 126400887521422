import React from 'react';

interface IIconProps {
  rotate?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const ArrowIcon = (props: IIconProps) => {
  const { rotate = 0, className, onClick } = props;

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      style={{ transform: `rotate(${rotate}deg)` }}
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#FFF" strokeWidth="3" fill="none" fillRule="evenodd">
        <path d="M11.177 1.557L22.324 12h0L11.177 22.443M22.015 12L.5 12.5" />
      </g>
    </svg>
  );
};

export default React.memo(ArrowIcon);
