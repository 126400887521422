import React from 'react';

import defaultImageMeta from '../../../images/publication-cover.png';
import {
  IArticleMetaGhostProps,
  IArticleMetaProps,
  IArticleSettings,
  ITagItem,
} from '../../../interfaces/Article';

import getAuthorProperties from './getAuthorProperties';
import ImageMeta from './ImageMeta';

import { tags as tagsHelper } from '@tryghost/helpers';
import { graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import url from 'url';
import config from 'utils/siteConfig';

const ArticleMetaGhost = ({ data, settings: settingsData, canonical }: IArticleMetaGhostProps) => {
  const ghostPost = data;
  const settings = settingsData.allGhostSettings.edges[0].node;

  const author = getAuthorProperties(ghostPost.primary_author);
  const publicTags = _.map(
    tagsHelper(ghostPost, { visibility: 'public', fn: (tag: ITagItem) => tag }),
    'name'
  );
  const primaryTag = publicTags[0] || '';
  const shareImage = ghostPost.feature_image ? ghostPost.feature_image : defaultImageMeta;
  const publisherLogo =
    settings.logo || config.siteIcon
      ? url.resolve(config.siteUrl, settings.logo || config.siteIcon)
      : undefined;

  return (
    <>
      <Helmet>
        <title>{ghostPost.meta_title || ghostPost.title}</title>
        <meta name="description" content={ghostPost.meta_description || ghostPost.excerpt} />
        <link rel="canonical" href={canonical} />

        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={ghostPost.og_title || ghostPost.meta_title || ghostPost.title}
        />
        <meta
          property="og:description"
          content={ghostPost.og_description || ghostPost.excerpt || ghostPost.meta_description}
        />
        <meta property="og:url" content={canonical} />
        <meta property="article:published_time" content={ghostPost.published_at} />
        <meta property="article:modified_time" content={ghostPost.updated_at} />
        {publicTags.map((keyword, i) => (
          <meta property="article:tag" content={keyword} key={i} />
        ))}
        {author.facebookUrl && <meta property="article:author" content={author.facebookUrl} />}

        <meta
          name="twitter:title"
          content={ghostPost.twitter_title || ghostPost.meta_title || ghostPost.title}
        />
        <meta
          name="twitter:description"
          content={ghostPost.twitter_description || ghostPost.excerpt || ghostPost.meta_description}
        />
        <meta name="twitter:url" content={canonical} />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={author.name} />
        {primaryTag && <meta name="twitter:label2" content="Filed under" />}
        {primaryTag && <meta name="twitter:data2" content={primaryTag} />}

        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(/^@/, '')}/`}
          />
        )}
        {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
        <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "Article",
                        "author": {
                            "@type": "Person",
                            "name": ${JSON.stringify(author.name)},
                            ${
                              author.image
                                ? author.sameAsArray
                                  ? `"image": "${author.image}",`
                                  : `"image": "${author.image}"`
                                : ''
                            }
                            ${author.sameAsArray ? `"sameAs": ${author.sameAsArray}` : ''}
                        },
                        ${
                          publicTags.length
                            ? `"keywords": ${JSON.stringify(_.join(publicTags, ', '))},`
                            : ''
                        }
                        "headline": ${JSON.stringify(ghostPost.meta_title || ghostPost.title)},
                        "url": "${canonical}",
                        "datePublished": "${ghostPost.published_at}",
                        "dateModified": "${ghostPost.updated_at}",
                        ${
                          shareImage
                            ? `"image": {
                                "@type": "ImageObject",
                                "url": "${shareImage}",
                                "width": "${config.shareImageWidth}",
                                "height": "${config.shareImageHeight}"
                            },`
                            : ''
                        }
                        "publisher": {
                            "@type": "Organization",
                            "name": ${JSON.stringify(settings.title)},
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${publisherLogo}",
                                "width": 60,
                                "height": 60
                            }
                        },
                        "description": ${JSON.stringify(
                          ghostPost.meta_description || ghostPost.excerpt
                        )},
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${config.siteUrl}"
                        }
                    }
                `}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};

const ArticleMetaQuery = (props: IArticleMetaProps) => {
  const renderArticle = (data: IArticleSettings) => <ArticleMetaGhost settings={data} {...props} />;
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsArticleMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
        }
      `}
      render={renderArticle}
    />
  );
};

export default ArticleMetaQuery;
