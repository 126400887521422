import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
}

const FacebookIcon = (props: IIconProps) => {
  const { width = 24, className } = props;
  const height = (width * 24) / 24;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12.614 21h-8.62A.993.993 0 0 1 3 20.006V3.993C3 3.445 3.445 3 3.993 3h16.014c.548 0 .993.445.993.993v16.013a.994.994 0 0 1-.993.994H15.42v-6.97h2.34l.35-2.717h-2.69V9.578c0-.786.218-1.322 1.346-1.322h1.438v-2.43a19.23 19.23 0 0 0-2.096-.107c-2.074 0-3.494 1.266-3.494 3.59v2.004H10.27v2.716h2.345V21z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default React.memo(FacebookIcon);
