import React from 'react';

import FacebookIcon from '../../images/Facebook';
import InstagramIcon from '../../images/Instagram';
import PinterestIcon from '../../images/Pinterest';
import TwitterIcon from '../../images/Twitter';

export interface ISocialLinksProps {
  className?: string;
}

export default function SocialLinks({ className }: ISocialLinksProps) {
  return (
    <div className={className}>
      <a href="https://www.facebook.com/leadeconomy" target="_blank" rel="noopener noreferrer">
        <FacebookIcon />
      </a>
      <a href="https://twitter.com/Lead_Economy" target="_blank" rel="noopener noreferrer">
        <TwitterIcon />
      </a>
      <a href="https://www.instagram.com/leadeconomy/" target="_blank" rel="noopener noreferrer">
        <InstagramIcon />
      </a>
      <a href="https://www.pinterest.com/leadeconomy/" target="_blank" rel="noopener noreferrer">
        <PinterestIcon />
      </a>
    </div>
  );
}
