import React from 'react';

import defaultImageMeta from '../../../images/publication-cover.png';

import ImageMeta from './ImageMeta';

import { graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import url from 'url';
import config from 'utils/siteConfig';

interface IWebsiteMetaSettings {
  allGhostSettings: {
    edges: Array<{
      node: {
        logo: any;
        description: string;
        title: string;
        twitter: string;
        allGhostSettings: any;
      };
    }>;
  };
}

interface IWebsiteMetaSettingsProp {
  settings: IWebsiteMetaSettings;
}

interface IWebsiteMetaProps {
  data: {
    title?: string;
    meta_title?: string;
    meta_description?: string;
    name?: string;
    feature_image?: string;
    description?: string;
    bio?: string;
    profile_image?: string;
  };
  canonical: string;
  title?: string;
  description?: string;
  image?: string;
  type: string;
}

interface IWebsiteMetaFullProps extends IWebsiteMetaProps, IWebsiteMetaSettingsProp {}

const WebsiteMeta = ({
  data,
  settings: settingsData,
  canonical,
  title,
  description,
  image,
  type,
}: IWebsiteMetaFullProps) => {
  const settings = settingsData.allGhostSettings.edges[0].node;

  const publisherLogo = url.resolve(config.siteUrl, settings.logo || config.siteIcon);
  let shareImage = image || data.feature_image || _.get(settings, 'cover_image', null);

  shareImage = shareImage ? url.resolve(config.siteUrl, defaultImageMeta) : null;

  description =
    description ||
    data.meta_description ||
    data.description ||
    config.siteDescriptionMeta ||
    settings.description;
  title = `${title || data.meta_title || data.name || data.title} - ${settings.title}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(/^@/, '')}/`}
          />
        )}
        {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
        <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org/",
                        "@type": "${type}",
                        "url": "${canonical}",
                        ${
                          shareImage
                            ? `"image": {
                                "@type": "ImageObject",
                                "url": "${shareImage}",
                                "width": "${config.shareImageWidth}",
                                "height": "${config.shareImageHeight}"
                            },`
                            : ''
                        }
                        "publisher": {
                            "@type": "Organization",
                            "name": ${JSON.stringify(settings.title)},
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${publisherLogo}",
                                "width": 60,
                                "height": 60
                            }
                        },
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${config.siteUrl}"
                        },
                        "description": ${JSON.stringify(description)}
                    }
                `}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};

const WebsiteMetaQuery = (props: IWebsiteMetaProps) => {
  const renderWebsiteMeta = (data: IWebsiteMetaSettings) => (
    <WebsiteMeta settings={data} {...props} />
  );
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsWebsiteMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
        }
      `}
      render={renderWebsiteMeta}
    />
  );
};

export default WebsiteMetaQuery;
