import React, { PureComponent, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import Modal, { IModalProps } from './ModalLayout';

import { CSSTransition } from 'react-transition-group';

const ANIMATION_DURATION = 300;

interface IProps extends IModalProps {
  children: ReactNode;
  isShown: boolean;
  zIndex?: number;
}

interface IState {
  isShown: boolean;
  isAnimating: boolean;
  isMounted: boolean;
}

export default class AnimatedModal extends PureComponent<IProps, IState> {
  static defaultProps = {
    zIndex: 10000,
  };

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    if (!prevState.isMounted) {
      return null;
    }

    const { isShown } = nextProps;
    if (isShown && !prevState.isShown) {
      // as soon as <body> get hight: 100vh and overflow hidden
      // the vertical scrollbar disapears what makes content to jump left on 15px
      // (or depends on browser's scrollbar width);
      // applying window width exclude scrollbar width keeps content placed
      const { width } = window.getComputedStyle(document.body);

      if (document.body) {
        document.body.style.width = width;
      }

      return { isShown };
    }

    return null;
  }
  modal?: HTMLDivElement; // eslint-disable-line

  constructor(props: IProps) {
    super(props);

    this.state = {
      isAnimating: props.isShown,
      isMounted: false,
      isShown: props.isShown,
    };
  }

  componentDidMount() {
    this.modal = document.createElement('div');
    // Despite of we specified zIndex in defaultProps
    // flowJS insists that zIndex mthis.modal && ight be undefined and
    // cannot coerce the variable to string. so added `|| 10000`
    this.modal.style.cssText = `position:absolute;z-index:${this.props.zIndex || 10000};`;

    if (this.modal && document.body) {
      document.body.appendChild(this.modal);
    }

    if (!this.state.isMounted) {
      this.setState({ isMounted: true });
    }

    if (this.props.isShown) {
      const rootEl = document.getElementById('leadpier-root');
      if (rootEl) {
        rootEl.style.filter = 'blur(10px)';
      }
      setTimeout(() => this.setState({ isAnimating: false }), ANIMATION_DURATION);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { isShown } = this.props;
    const rootEl = document.getElementById('leadpier-root');

    if (isShown !== prevProps.isShown && rootEl) {
      rootEl.style.filter = `blur(${isShown ? 10 : 0}px)`;
    }

    if (isShown && !prevProps.isShown) {
      this.setState({ isAnimating: true });
      setTimeout(() => this.setState({ isAnimating: false }), ANIMATION_DURATION);
    } else if (!isShown && prevProps.isShown) {
      // waiting until animation is done
      setTimeout(() => {
        if (document.body) {
          document.body.style.width = '';
        }
      }, ANIMATION_DURATION);
    }
  }

  componentWillUnmount() {
    if (this.modal && document.body) {
      document.body.removeChild(this.modal);
    }
  }

  render() {
    const { isShown, zIndex, ...restProps } = this.props;
    const { isAnimating, isMounted } = this.state;

    if (!isMounted || !this.modal) {
      return null;
    }

    const AnimatedModal = (
      <CSSTransition classNames="modal" in={isShown} timeout={ANIMATION_DURATION} unmountOnExit>
        <Modal {...restProps} isAnimating={isAnimating} />
      </CSSTransition>
    );

    return createPortal(AnimatedModal, this.modal);
  }
}
