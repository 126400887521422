import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
}

const PinterestIcon = (props: IIconProps) => {
  const { width = 24, className } = props;
  const height = (width * 24) / 24;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M2 12a10 10 0 0 0 5.986 9.159c-.028-.698-.005-1.537.174-2.296l1.287-5.45s-.32-.638-.32-1.581c0-1.482.86-2.589 1.929-2.589.91 0 1.349.683 1.349 1.501 0 .915-.583 2.282-.883 3.549-.25 1.06.532 1.926 1.578 1.926 1.895 0 3.17-2.433 3.17-5.317 0-2.191-1.475-3.832-4.16-3.832-3.034 0-4.923 2.262-4.923 4.79 0 .87.256 1.485.659 1.96.185.219.21.307.143.558l-.203.802c-.067.254-.272.344-.5.25-1.398-.57-2.049-2.1-2.049-3.82 0-2.84 2.396-6.247 7.147-6.247 3.818 0 6.331 2.763 6.331 5.729 0 3.923-2.18 6.853-5.396 6.853-1.08 0-2.095-.583-2.443-1.246 0 0-.58 2.304-.703 2.749-.212.77-.627 1.541-1.007 2.142.9.265 1.85.41 2.834.41 5.523 0 10-4.477 10-10S17.523 2 12 2C6.478 2 2 6.477 2 12z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default React.memo(PinterestIcon);
