import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
}

const TwitterIcon = (props: IIconProps) => {
  const { width = 24, className } = props;
  const height = (width * 24) / 24;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M22 5.003a7.95 7.95 0 0 1-2.357.673 4.26 4.26 0 0 0 1.805-2.364 8.022 8.022 0 0 1-2.606 1.037A4.024 4.024 0 0 0 15.847 3c-2.266 0-4.103 1.913-4.103 4.272 0 .335.036.661.106.974-3.41-.178-6.434-1.88-8.458-4.464a4.387 4.387 0 0 0-.555 2.148c0 1.482.724 2.79 1.825 3.556a3.966 3.966 0 0 1-1.858-.535v.054c0 2.07 1.414 3.797 3.29 4.19a3.96 3.96 0 0 1-1.852.072c.522 1.698 2.037 2.933 3.833 2.967A8.028 8.028 0 0 1 2 18.004a11.283 11.283 0 0 0 6.29 1.92c7.547 0 11.675-6.511 11.675-12.156 0-.186-.005-.37-.012-.553A8.534 8.534 0 0 0 22 5.003"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default React.memo(TwitterIcon);
