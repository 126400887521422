import React, { useCallback, useEffect, useState } from 'react';

import Chevron from '../../images/Chevron';
import CloseIcon from '../../images/Close';
import Humburger from '../../images/Humburger';
import Logo from '../../images/logo/Logo';
import { ISiteSettings } from '../common/Layout';
import ContactForm from '../Forms/Contact';
import Modal from '../Modal/index';
import SideNav from '../SideNav/SideNav';
import SocialLinks from '../SocialLinks/SocialLinks';

import style from './Header.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export interface IHeaderProps {
  className?: string;
  site: ISiteSettings;
  isWhite?: boolean;
}

let scrollTop: number;
let currentScrollTop = 0;

export default function Header({ className, isWhite = false, site }: IHeaderProps) {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const showSidebarMenu = () => {
    if (!isSidebarVisible) {
      setSidebarVisibility(true);
    }
  };

  const handleSidenavClose = useCallback(() => {
    if (isSidebarVisible) {
      setSidebarVisibility(false);
    }
  }, [isSidebarVisible]);

  const [scrollY, setScrollY] = useState(0);
  const navbar = typeof document !== 'undefined' ? document.getElementById('nav') : undefined;

  function logit() {
    setScrollY(window.pageYOffset);

    if (navbar) {
      const navbarHeight = navbar.offsetHeight;

      currentScrollTop = scrollY;

      if (scrollTop < currentScrollTop && scrollY > navbarHeight + navbarHeight) {
        navbar.classList.add(style.scrollDown);
      } else if (scrollTop > currentScrollTop && !(scrollY <= navbarHeight)) {
        navbar.classList.remove(style.scrollDown);
      }
      scrollTop = currentScrollTop;
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener('scroll', logit);
    };
  });

  const [isContactModalShown, setContactModalShown] = useState(false);
  // Contact Modal Toggle
  const showContactModal = () => {
    setContactModalShown(true);
  };

  const hideContactModal = () => {
    setContactModalShown(false);
  };

  return (
    <>
      <Modal
        isShown={isContactModalShown}
        isCloseButtonShown
        onClose={hideContactModal}
        className={style.contactModal}
      >
        <ContactForm />
      </Modal>

      <header
        id="nav"
        className={cn(className, style.header, {
          [style.scrolled]: scrollY > 2,
          [style.isWhite]: isWhite,
        })}
      >
        <div className="container">
          <div className="d-flex align-items-center">
            <div className={cn('col col-xl', style.logo)}>
              <a href="/">
                <Logo />
              </a>
            </div>
            <div className={cn('col-auto d-none d-lg-flex', style.links)}>
              <ul>
                <li>
                  <Link activeClassName={style.active} to="/affiliate">
                    Affiliate
                  </Link>
                </li>
                <li>
                  <Link activeClassName={style.active} to="/brands">
                    Advertiser
                  </Link>
                </li>
                <li>
                  <div>
                    Company <Chevron rotate={90} />
                  </div>
                  <ul>
                    <li>
                      <Link activeClassName={style.active} to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link activeClassName={style.active} to="/wheretofindus">
                        Where to find us
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <div onClick={showContactModal}>Contact</div>
                </li>
              </ul>
            </div>
            <div className={cn('col-auto d-lg-none', style.toggleButton)}>
              <Humburger onClick={showSidebarMenu} aria-label="Open Side Nav" />
            </div>
          </div>
        </div>
      </header>
      <SideNav isOpen={isSidebarVisible} onClose={handleSidenavClose}>
        <div className={style.buttonsSection}>
          <div className="row">
            <div className="col d-flex align-center">
              <button
                className={style.closeButton}
                onClick={handleSidenavClose}
                aria-label="Close Side Nav"
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={style.sideNavLinks}>
          <div className={style.headerLinks}>
            <Link activeClassName={style.active} to="/">
              Home
            </Link>
            <Link activeClassName={style.active} to="/affiliate">
              Affiliate
            </Link>
            <Link activeClassName={style.active} to="/brands">
              Advertiser
            </Link>
            {/* <Link activeClassName={style.active} to="/list-management">
              List Management
            </Link> */}
            <Link activeClassName={style.active} to="/about">
              About
            </Link>
            {/* <Link activeClassName={style.active} to="/blog">
              Blog
            </Link> */}
            <Link activeClassName={style.active} to="/wheretofindus">
              Where to find us
            </Link>
            <div onClick={showContactModal}>Contact</div>
          </div>
          <div className={style.footerLinks}>
            <Link activeClassName={style.active} to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link activeClassName={style.active} to="/terms-and-conditions">
              Terms and Conditions
            </Link>
            <Link activeClassName={style.active} to="/compliance">
              Compliance
            </Link>
            <Link activeClassName={style.active} to="/faq">
              FAQ's
            </Link>
            <SocialLinks className={style.socialLinks} />
          </div>
        </div>
      </SideNav>
    </>
  );
}
