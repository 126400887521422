import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
}

const InstagramIcon = (props: IIconProps) => {
  const { width = 24, className } = props;
  const height = (width * 24) / 24;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M10.972 2h2.056c1.8.004 2.18.018 3.095.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 0 1 1.772 1.153 4.902 4.902 0 0 1 1.153 1.772c.247.636.416 1.363.465 2.427.049 1.067.06 1.407.06 4.123s-.011 3.056-.06 4.123c-.049 1.064-.218 1.791-.465 2.427a4.904 4.904 0 0 1-1.153 1.772 4.902 4.902 0 0 1-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.049-1.407.06-4.123.06s-3.056-.011-4.123-.06c-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 0 1-1.772-1.153 4.902 4.902 0 0 1-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.042-.914-.056-1.295-.06-3.095v-2.056c.004-1.8.018-2.18.06-3.095.049-1.064.218-1.791.465-2.427a4.9 4.9 0 0 1 1.153-1.772A4.902 4.902 0 0 1 5.45 2.525c.636-.247 1.363-.416 2.427-.465.914-.042 1.295-.056 3.095-.06h2.056zm1.584 1.802h-1.112c-2.165.002-2.509.014-3.485.058-.975.045-1.504.208-1.857.344a3.1 3.1 0 0 0-1.15.748 3.1 3.1 0 0 0-.748 1.15c-.136.353-.3.882-.344 1.857-.044.976-.056 1.32-.058 3.485v1.112c.002 2.165.014 2.508.058 3.485.045.975.208 1.504.344 1.857.182.466.399.8.748 1.15.35.35.684.566 1.15.748.353.137.882.3 1.857.344 1.015.046 1.346.057 3.754.058h.574c2.408 0 2.739-.012 3.754-.058.975-.045 1.504-.207 1.857-.344.466-.182.8-.399 1.15-.748a3.1 3.1 0 0 0 .748-1.15c.137-.353.3-.882.344-1.857.044-.977.056-1.32.058-3.485v-1.112c-.002-2.165-.014-2.509-.058-3.485-.045-.975-.207-1.504-.344-1.857a3.1 3.1 0 0 0-.748-1.15 3.1 3.1 0 0 0-1.15-.748c-.353-.136-.882-.3-1.857-.344-.976-.044-1.32-.056-3.485-.058zM12 6.865a5.135 5.135 0 1 1 0 10.27 5.135 5.135 0 0 1 0-10.27zm0 1.802a3.333 3.333 0 1 0 0 6.666 3.333 3.333 0 0 0 0-6.666zm5.338-3.205a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default React.memo(InstagramIcon);
