import React, { useState } from 'react';

import { TertiaryButton } from '../Buttons/TertiaryButton';
import ContactForm from '../Forms/Contact';
import Modal from '../Modal/index';

import style from './BigBanner.scss';

import cn from 'classnames';

interface IBigBannerProps {
  className?: string;
}

export default function BigBanner(props: IBigBannerProps) {
  const [isContactModalShown, setContactModalShown] = useState(false);

  // Contact Modal Toggle
  const showContactModal = () => {
    setContactModalShown(true);
  };

  const hideContactModal = () => {
    setContactModalShown(false);
  };

  return (
    <div className={cn(style.bigBanner, props.className)}>
      <div className="container">
        <div className="d-flex flex-wrap">
          <div className="col-12">
            <h2>
              Let’s talk business
              <small>
                After 10 years in the lead gen industry, we earned the respect of our partners by{' '}
                <br className="d-none d-lg-block" />
                consistently delivering high-quality leads.
              </small>
            </h2>
            <TertiaryButton onClick={showContactModal} className={style.primaryButton}>
              Contact us
            </TertiaryButton>
          </div>
        </div>
      </div>
      <Modal
        className={style.contactModal}
        isShown={isContactModalShown}
        isCloseButtonShown
        onClose={hideContactModal}
      >
        <ContactForm />
      </Modal>
    </div>
  );
}
