import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function formatPublishedAtDate(date: string) {
  const day = 24 * 60 * 60 * 1000;
  if (new Date().getTime() - new Date(date).getTime() >= day) {
    if (new Date().getFullYear() === new Date(date).getFullYear()) {
      return dayjs(date).format('MMM D');
    }
    return dayjs(date).format('MMM D, YYYY');
  }
  return dayjs(date).fromNow();
}

export function splitPostHtml(html: string, excerpt: string | null) {
  if (excerpt) {
    const maxCount = excerpt.replace(/\n/g, '').trim().length;
    let charCount = 0;
    let isInsideTag = false;
    let openTagCount = 0;
    let i: number = 0;

    while (charCount < maxCount || openTagCount > 0 || isInsideTag) {
      const char = html[i];
      const nextChar = html[i + 1];
      if (char === '<') {
        isInsideTag = true;
        if (nextChar === '/') {
          openTagCount -= 1;
        } else {
          openTagCount += 1;
        }
      } else if (char === '>') {
        isInsideTag = false;
      } else if (!isInsideTag) {
        charCount += 1;
      }
      i += 1;
    }

    return [html.slice(0, i), html.slice(i)];
  }

  return ['', html];
}

export function isEmail(str: string | null | undefined) {
  return !!str && /^[^@\s]+@[^\s]+\.[a-zA-Z]+$/.test(str) === true;
}

export function validPhoneNumber(str: string | null | undefined) {
  return !!str && /^\([2-9]\d{2}\)\s[2-9]\d{2}-\d{4}$/.test(str.toString()) === true;
}

export function getCookie(name: string, cookie: string) {
  const pattern = `${name}=([^;]+)(?:;|$)`;
  const [, value] = cookie.match(new RegExp(pattern)) || [];
  return value;
}

export function isMobile(): boolean {
  if (typeof navigator !== 'undefined' && typeof navigator.userAgent !== 'undefined') {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  return false;
}
