import * as React from 'react';

import CloseIcon from '../../images/Close';

import style from './Modal.scss';

import cn from 'classnames';

export interface IModalProps {
  title?: string;
  subTitle?: string;
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  closeIconClassName?: string;
  children: React.ReactNode;
  onClose?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  isFullScreen?: boolean;
  isAnimating?: boolean;
  canBeClosedByEsc?: boolean;
  canBeClosedByOutsideClick?: boolean;
  isCloseButtonShown?: boolean;
}

export default class Modal extends React.Component<IModalProps> {
  static defaultProps = {
    canBeClosedByEsc: true,
    canBeClosedByOutsideClick: true,
    className: '',
    closeIconClassName: '',
    containerClassName: '',
    isAnimating: false,
    isCloseButtonShown: false,
    isFullScreen: false,
    onClose: () => new Object(),
    onKeyDown: () => new Object(),
  };
  modal = React.createRef<HTMLDivElement>();
  modalBody = React.createRef<HTMLDivElement>();
  windowScrollTop: number = 0;
  initialBodyStyles: React.CSSProperties = {};

  nav = document.getElementById('nav');

  componentDidMount() {
    this.positionDialog();
    // use browser's window rather than parent's one (like iframe's)
    // to measure viewport height unit

    this.windowScrollTop = window.pageYOffset;

    // To prevent scrolling under modal in case the app is embedded to iframe
    // set inline styles to website body rather than iframe's

    // But make sure we save already specified style properties to prevent break
    // of customer's website layout

    if (document.body) {
      this.initialBodyStyles = Array.from(document.body.style).reduce<React.CSSProperties>(
        (acc, s: any) => {
          return { ...acc, [s]: style[s] };
        },
        {}
      );
      // Don't use 'cssText' to set all properties with 1 determine
      // It will override other inline styles in <body  e.g. we lost
      // background-color: transparent in body styles in MessengerWidgetWrapper iframe

      // Note: Order matters
      // If set position fixed goes 1st it will change body size and offsetWidth will be incorrect

      document.body.style.width = '100%';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${this.windowScrollTop}px`;
      document.body.style.overflow = 'hidden';
      (this.nav as any).style.transform = 'translateY(-120%)';
    }

    window.addEventListener('resize', this.positionDialog);
    if (this.modal.current) {
      this.modal.current.addEventListener('click', this.handleOverlayClick);
    }
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate() {
    this.positionDialog();
  }

  componentWillUnmount() {
    if (document && document.body) {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.overflow = '';
      document.body.style.height = '';
      document.body.style.width = '';
      (this.nav as any).style.transform = '';
    }

    // Restore body inline styles
    Object.entries(this.initialBodyStyles).forEach(([key, value]: any[]) => {
      document.body.style[key] = value;
    });
    // Restore scroll position after modal is closed.
    // Check that window.scroll is a funcion
    // On some customer websites scroll function overwritten with object by some
    // probably WP pluging and/or UI builder or js library.
    // On their websites calling .scroll() throws an error and crashes the app
    if (this.windowScrollTop && typeof window.scroll === 'function') {
      window.scroll(0, this.windowScrollTop);
    }

    window.removeEventListener('resize', this.positionDialog);
    if (this.modal.current) {
      this.modal.current.removeEventListener('click', this.handleOverlayClick);
    }
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleHeightChange = () => {
    this.positionDialog();
  };

  handleOverlayClick = (e: Event) => {
    const { canBeClosedByOutsideClick, onClose = () => new Object() } = this.props;
    if (e.target === this.modal.current && canBeClosedByOutsideClick) {
      onClose();
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const { canBeClosedByEsc, onKeyDown, onClose = () => new Object() } = this.props;
    if (e.key === 'Escape' && canBeClosedByEsc) {
      onClose();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  positionDialog = () => {
    const { isFullScreen, isAnimating } = this.props;
    const { left = 0 } = this.modalBody.current
      ? this.modalBody.current.getBoundingClientRect()
      : {};

    const zoomCoef = isAnimating ? 1.1 : 1;

    if (!isFullScreen) {
      const margin = Math.min(left * zoomCoef, 100);
      if (this.modal.current) {
        this.modal.current.style.paddingTop = `${margin}px`;
        this.modal.current.style.paddingBottom = `${margin}px`;
      }
    } else if (this.modal.current && this.modalBody.current) {
      // Reset margins when modal changes its size to full screen.

      this.modal.current.style.paddingTop = '0px';
      this.modal.current.style.paddingBottom = '0px';
      this.modalBody.current.style.height =
        this.modalBody.current.scrollHeight > window.innerHeight ? 'auto' : '100%';
    }
  };

  render() {
    const {
      title,
      children,
      className,
      containerClassName,
      contentClassName,
      closeIconClassName,
      onClose,
      isFullScreen,
      isCloseButtonShown,
      subTitle,
    } = this.props;
    return (
      <div
        ref={this.modal}
        className={cn(style.container, containerClassName, {
          [style.centerContent]: !isFullScreen,
        })}
      >
        <div
          ref={this.modalBody}
          className={cn(className, style.dialog, {
            [style.fullScreen]: isFullScreen,
          })}
        >
          <header className={style.header}>
            {<h4>{title}</h4> || null}
            {<p>{subTitle}</p> || null}
            {isCloseButtonShown ? (
              <button className={cn(closeIconClassName, style.closeIcon)} onClick={onClose}>
                <CloseIcon width={30} />
              </button>
            ) : null}
          </header>

          <div className={cn(contentClassName, style.body)}>{children}</div>
        </div>
      </div>
    );
  }
}
