import React from 'react';

import secureIcons from '../../images/footer/secure-icons.svg';
import { ISiteSettings } from '../common/Layout';
import SocialLinks from '../SocialLinks/SocialLinks';

import style from './Footer.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export interface IFooterProps {
  className?: string;
  site: ISiteSettings;
}

export default function Footer({ className, site }: IFooterProps) {
  return (
    <footer className={cn(className, style.footer)}>
      <div className="container">
        <div className="d-flex flex-wrap">
          <div className="col-12">
            <div className={style.links}>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
              <Link to="/compliance">Compliance</Link>
              <Link to="/faq">FAQ's</Link>
            </div>
            <SocialLinks className={style.socialLinks} />
            <img src={secureIcons} alt="Secure" />
            <div className={style.disclaimer}>
              <p>
                &copy; 2018—{new Date().getFullYear()} {site.title} All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
